// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-center-tsx": () => import("./../../../src/pages/learning-center.tsx" /* webpackChunkName: "component---src-pages-learning-center-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-services-co-design-tsx": () => import("./../../../src/pages/services/co-design.tsx" /* webpackChunkName: "component---src-pages-services-co-design-tsx" */),
  "component---src-pages-services-consulting-service-tsx": () => import("./../../../src/pages/services/consulting-service.tsx" /* webpackChunkName: "component---src-pages-services-consulting-service-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-library-management-tsx": () => import("./../../../src/pages/services/library-management.tsx" /* webpackChunkName: "component---src-pages-services-library-management-tsx" */),
  "component---src-pages-services-package-design-tsx": () => import("./../../../src/pages/services/package-design.tsx" /* webpackChunkName: "component---src-pages-services-package-design-tsx" */),
  "component---src-pages-services-pcb-design-layout-tsx": () => import("./../../../src/pages/services/pcb-design-layout.tsx" /* webpackChunkName: "component---src-pages-services-pcb-design-layout-tsx" */),
  "component---src-pages-services-signal-integrity-tsx": () => import("./../../../src/pages/services/signal-integrity.tsx" /* webpackChunkName: "component---src-pages-services-signal-integrity-tsx" */),
  "component---src-pages-services-valor-dfm-services-tsx": () => import("./../../../src/pages/services/valor-dfm-services.tsx" /* webpackChunkName: "component---src-pages-services-valor-dfm-services-tsx" */)
}

